@import 'variables';

ol.products {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;

    .item-details {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }
}

.products,
.knowledgebase {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  gap: 16px;
}

.product-item {
  display: grid;
  border-bottom: 1px solid $color-neutral-300;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 8px;
  align-items: center;
}

.products,
.knowledgebase {
  &.section.hero.v9 {
    padding-top: 169px;
    padding-bottom: 82px;
  }

  .position-absolute.card-top-bg---bottom.pricing-bg {
    min-height: 24%;
  }
}

.vmc-product {
  display: flex;
  align-items: self-start;
}

.product-module {
  width: 560px;
}

.product-detail-desc {

  h4,
  p {
    margin-top: 10px;
  }
}

.product-detail-desc .price {
  font-weight: 700;
  font-size: 26px;
  margin-top: 30px;
  color: $red-400;
}

.price .old-price,
.product-price .old-price,
.price .old-price {
  color: gray;
  text-decoration: line-through;
}

.product-detail-desc {
  .quantity {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    align-items: center;
  }

  .buttons {
    display: flex;
    gap: 30px;
  }
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}

.product-card {
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  color: #324d67;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.product-image {
  border-radius: 15px;
  background-color: #ebebeb;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.product-name {
  font-weight: 500;
}

.product-price {
  font-weight: 800;
  margin-top: 6px;
  color: black;
}

.product-heading {
  font-size: 32px;

  &.enterprise {
    margin-bottom: 4px;
  }
}

.products-heading {
  text-align: center;
  margin: 40px 0px;
  color: #324d67;

  h2 {
    font-size: 40px;
    font-weight: 800;
  }

  p {
    font-size: 16px;
    font-weight: 200;
  }
}

.product-container {
  margin-top: 15px;
  overflow: auto;
  max-height: 70vh;
  padding: 20px 10px;
}

.product {
  /* display: flex; */
  gap: 30px;
  padding: 20px;

  .cart-product-image {
    width: 150px;
    height: 150px;
    border-radius: 8px;
  }
}

.product-column {
  padding-left: 87px;
  padding-top: 44px;

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-top: 24px;
  }
}

.item-desc {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  .product-name {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .bottom {
    margin-top: 60px;
  }
}

.flex {
  h5 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }
}

.total {
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 22px;
  }
}

.remove-item {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.product-detail-container {
  display: flex;
  gap: 40px;
  margin: 40px;
  margin-top: 60px;
  color: #324d67;
}

.product-detail-image {
  border-radius: 15px;
  background-color: #ebebeb;
  width: 400px;
  height: 400px;
  cursor: pointer;
  transition: .3s ease-in-out;

  &:hover {
    background-color: $red-400;
  }
}

.small-images-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.small-image {
  border-radius: 8px;
  background-color: #ebebeb;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.selected-image {
  background-color: $red-400;
}

.reviews {
  color: $red-400;
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.reviews p {
  color: #324d67;
  margin-top: 0px;
}

.product-detail-desc {

  h4,
  p {
    margin-top: 10px;
  }
}

.product-detail-desc .price {
  font-weight: 700;
  font-size: 26px;
  margin-top: 30px;
  color: $red-400;
}

.price .old-price,
.product-price .old-price,
.price .old-price {
  color: gray;
  text-decoration: line-through;
}

.product-detail-desc {
  .quantity {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    align-items: center;
  }

  .buttons {
    display: flex;
    gap: 30px;
  }
}

.quantity-desc {
  border: 1px solid gray;
  padding: 6px;

  span {
    font-size: 16px;
    padding: 6px 12px;
    cursor: pointer;
  }

  .minus {
    border-right: 1px solid gray;
    color: $red-400;
  }

  .num {
    border-right: 1px solid gray;
    font-size: 20px;
  }

  .plus {
    color: rgb(49, 168, 49);
  }
}

.pricing-column.featured {
  background-color: $color-neutral-200;
}

.pricing-table-top {
  min-height: 300px;
  background-color: #fff;
  border-bottom: 1px solid $color-neutral-300;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 58px 24px 46px;
  display: flex;

  &.first {
    align-items: flex-start;
    padding-left: 0;
  }

  &.featured {
    background-color: $color-neutral-200;
  }
}

.pricing-content-wrapper {
  max-height: 94px;
  min-height: 94px;
  border-bottom: 1px solid $color-neutral-300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;

  &.left {
    align-items: flex-start;
  }
}

.maylike-products-wrapper {
  margin-top: 120px;

  h2 {
    text-align: center;
    margin: 50px;
    color: #324d67;
    font-size: 28px;
  }
}

.maylike-products-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.product-max-qty {
  margin-top: 10px;
}

.reviews p {
  color: #324d67;
  margin-top: 0px;
}